<script>
  import { orderItemAdditionMixin } from '../../mixins/orderItemAdditionMixin';

  export default {
    props: [
      'additionalItem',
      'additionalItemId'
    ],

    mixins: [orderItemAdditionMixin],

    //components: {},

    mounted() {},

    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      currentAdditionalItem() {
        return this.additionalItem;
      },

      currentAdditionalItemPart() {
        return this.$store.getters['orderItemAddition/orderItemAdditionPartById'](this.additionalItem.part_id);
      },

      orderItemAdditionSelectOptions() {
        return this.$parent.orderItemAdditionSelectOptions;
      },

      supplyOnlySelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffSupplyOnlyOptions;
      },

      errors() {
        return this.$store.getters['orderItemAddition/getErrorsById'](this.currentAdditionalItem.id);
      },
    },

    methods: {
      getInputName(id, field) {
        return 'order_item_additions['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },
    },

    watch: {

    }
  };
</script>

<template>
  <tr v-if="currentAdditionalItem.isNew == false">
    <td>{{ currentAdditionalItemPart.name }}</td>
    <td>{{ currentAdditionalItem.addtl_item_material_cost_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_mfg_labor_cost_formatted }} </td>
    <td>{{ currentAdditionalItem.addtl_item_pkg_labor_hours_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_pkg_labor_cost_formatted }}</td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['supply_only'] ? 'is-invalid' : ''"
        v-model="currentAdditionalItem.supply_only"
        :disabled="orderProposal.userCanEdit == false"
        v-order-item-addition-field-directive>
        <option v-for="option in supplyOnlySelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>{{ currentAdditionalItem.addtl_item_install_labor_cost_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_total_cost_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_unit_cost_formatted }}</td>
    <td></td>
    <td>{{ currentAdditionalItem.addtl_item_material_expense_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_total_gross_profit_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_subtotal_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_dealer_commission_fee_formatted }}</td>
    <td>{{ currentAdditionalItem.addtl_item_total_price_formatted }}</td>
    <td></td>
    <td></td>
  </tr>
</template>
