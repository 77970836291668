<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'shadeGroupId',
      'shadeUnit'
    ],

    data() {
      return {
        inFocus: false,
        justSavedShade: false,
      }
    },

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('DealerCalcTableRow.vue - Mounted');
        console.log('shadeGroupId');
        console.log(this.shadeGroupId);
        console.log('shadeLineItem');
        console.log(this.shadeUnit);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      shadeLineItem() {
        return this.shadeUnit;
      },

      supplyOnlySelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffSupplyOnlyOptions;
      },

      errors() {
        return this.$parent.errors;
      },
    },

    methods: {
      hasFocus() {
        this.inFocus = ( this.justSavedShade === true ) ? false : true;
        this.justSavedShade = false;
      },

      checkForChanges(event) {
        var hasChanges = false;
        this.shadeGroup.shadeLineItems.forEach(function(sli) {
          if( sli.hasChanges === true ) {
            hasChanges = true;
          }
        });

        if( hasChanges === false || this.inFocus === false ) {
          this.inFocus = false;
          return;

        } else {
          this.saveShadeGroup();
        }
      },

      saveShadeGroup() {
        //As a general rule, when a "singular" shade line item is saved, we actually want to go ahead and save the entire group
        if( this.shadeGroup.isNew == true ) {
          this.$store.dispatch('shadeGroups/createShadeGroup', this.shadeGroup.id);
        } else {
          this.$store.dispatch('shadeGroups/updateShadeGroup', this.shadeGroup.id);
        }

        this.inFocus = false;
        this.justSavedShade = true;
      },
    },

    directives: {
      clickOutside: {
        bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
            if( $(event.target).closest(el).length <= 0 && vnode.context.inFocus === true ) {
              vnode.context[binding.expression](event);
            }
          };
          document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent);
        }
      }
    }
  };
</script>


<template>
  <tr v-if="shadeLineItem.isNew === false">
    <td>{{ shadeLineItem.sort_order_number_textual }}</td>
    <td>{{ shadeLineItem.total_material_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_mfg_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.pkg_labor_hours_formatted }}</td>
    <td>{{ shadeLineItem.pkg_labor_cost_formatted }}</td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['supply_only'] ? 'is-invalid' : ''"
        v-model="shadeLineItem.supply_only"
        @click="hasFocus"
        v-click-outside="checkForChanges"
        :disabled="shadeLineItem.isInnerCoupledShade || orderProposal.userCanEdit == false">
        <option v-for="option in supplyOnlySelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>{{ shadeLineItem.install_total_cost_formatted }}</td>
    <td>{{ shadeLineItem.total_shade_cost_formatted }}</td>
    <td>{{ shadeLineItem.unit_cost_formatted }}</td>
    <td>{{ shadeLineItem.general_labor_expense_formatted }}</td>
    <td>{{ shadeLineItem.general_material_expense_formatted }}</td>
    <td>{{ shadeLineItem.gross_profit_formatted }}</td>
    <td>{{ shadeLineItem.subtotal_formatted }}</td>
    <td>{{ shadeLineItem.commission_fee_formatted }}</td>
    <td>{{ shadeLineItem.total_formatted }}</td>
    <td>{{ shadeLineItem.cost_per_shade_formatted }}</td>
    <td>{{ shadeLineItem.pct_of_total_formatted }}</td>
  </tr>
</template>
