<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import { normalizeRelations, resolveRelations } from '../../../../store/helpers/helpers'
import { ENDPOINT_ADD_SHADE_GROUP,
		 ENDPOINT_IMPORT_SHADE_GROUP,
          ENDPOINT_CREATE_SHADE_GROUP,
          ENDPOINT_UPDATE_SHADE_GROUP,
          ENDPOINT_DESTROY_SHADE_GROUP,
          ENDPOINT_COPY_SHADE_GROUP,
          ENDPOINT_SORT_SHADE_GROUP
        } from '../../../../store/endpoints'
import { PopoverPlugin } from 'bootstrap-vue';
  export default {
    namespaced: true,
	name: 'Modal',
	data(){
		return {
			soid: this.$attrs.ostmasterorderproposal.shade_order_id,
			ifile: null,
			content: [],
			parsed: false
		};
	},
	mounted() {
		console.log("Modal Vue Mounted.");
		console.log(this.$attrs);
		/*console.log(this.$attrs.ostmastershadeorder);
		console.log(this.$attrs.ostmasterorderproposal);
		console.log(this.$attrs.ostmasterproject);
		console.log(this.$attrs.ostmastershadegroups);
		console.log(this.$attrs.ostmastershadeunitconfigs);
		console.log(this.$attrs.ostmasterestimatorshadeunitconfigs);
		console.log(this.$attrs.ostprimaryshadeselectoptions);
		console.log(this.$attrs.ostmasterorderproposalselectoptions);
		console.log(this.$attrs.ostmasterusercaneditestimate);*/
	},
    methods: {
      close() {
        this.$emit('close');
      },
	  handleFileUpload( event ){
		this.ifile = event.target.files[0];
      },
	  
	  uploadShade() {
		popUpAssembly();
		
		return new Promise((resolve, reject) => {
		    let formData = new FormData();
			formData.append('csv_file', this.ifile);

			// ENDPOINT_IMPORT_SHADE_GROUP = /projects/estimate/shade-groups/import/
			axios.post(ENDPOINT_IMPORT_SHADE_GROUP+this.$attrs.ostmasterorderproposal.shade_order_id, formData, {headers:{'Content-Type':'multipart/form-data'}}).then((response) => {

			let groupIds = "";
			let dataStatus = response.data.status;
			if (dataStatus.indexOf("::") > -1) {
				let ray = dataStatus.split('::');
				groupIds = ray[0];
				dataStatus = ray[1];
			}


			  this.$emit('close');
			  flash(response.data.status_type, dataStatus,10000);
			  this.ifile = null;
			  $("#upload_csv").val('');


			  if(response.data.status_type == "alert-success")
			  { 
					
					// display any errors
					if ( response.data.data.length > 0)
					{
						let array = response.data.data;
						let str = '';
						let line = '';				
						let head = array[0];
							for (var index in array[0]) {
								line += index + ',';
							}
						line = line.slice(0, -1);
						str += line + '\r\n';

						for (let i = 0; i < array.length; i++) {
							let line = '';
								for (let index in array[i]) {
									line += array[i][index] + ',';
								}

							line = line.slice(0, -1);
							str += line + '\r\n';
						}
						
						let downloadLink = document.createElement("a");
						let blob = new Blob(["\ufeff", str]);
						let url = URL.createObjectURL(blob);
						downloadLink.href = url;
						downloadLink.download = "error_data.csv";
						document.body.appendChild(downloadLink);
						downloadLink.click();
						document.body.removeChild(downloadLink);
						setTimeout(function(){flash("alert-success", "Error data downloaded successfully.");},5000);
					}

					updateAssemblies(groupIds);
				}

				resolve(response);
			})
			.catch(error => {
			  this.$emit('close');

			  flash(error.response.data.status_type, error.response.data.status);
			  this.ifile = null;
			  $("#upload_csv").val('');
			  reject(error.response.data);
			});
		});		
	  },
	  JSON2CSV(objArray){
		let array = objArray;
		let str = '';
		let line = '';
		
		let head = array[0];
			for (var index in array[0]) {
				line += index + ',';
			}

		line = line.slice(0, -1);
		str += line + '\r\n';

		for (let i = 0; i < array.length; i++) {
			let line = '';
				for (let index in array[i]) {
					line += array[i][index] + ',';
				}

			line = line.slice(0, -1);
			str += line + '\r\n';
		}
		return str;
	  },
    },
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
	  <form @submit.prevent="uploadShade">
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
            Upload Shades
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
			style="position:relative;top:-15px;"
          >
            x
          </button>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
				<input style="margin: auto;" accept="text/csv" type="file" id="upload_csv" name="upload_csv" class="form-control" v-on:change="handleFileUpload"/>
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">
            <input type="button" class="btn btn-green" value="UPLOAD" @click="uploadShade"/>
			<!--<button
            type="button"
            class="btn btn-green"
            @click="close"
            aria-label="Close modal"
			>Cancel</button>-->
          </slot>
          
        </footer>
		</form>
      </div>
    </div>
  </transition>
</template>

<style>
#take-off .btn-upload-shaded-group:before {
    content: "\F067";
    display: inline;
    font-family: FontAwesome;
    margin-right: 0.5em;
}
#take-off .btn-upload-shaded-group {
    background-color: #ec881e
}
  .modal-backdrop {
    position: fixed;
    top: 37%;
    bottom: 0;
    left: 37%;
    right: 0;
	width: 300px;
    height: 223px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
	opacity: 1;
  }

  .modal {
    width: 300px;
    height: 223px;
    top: 37%;
    left: 37%;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header, .modal-body,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #ec881e;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #d0700a;
    border: 1px solid #d0700a;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>