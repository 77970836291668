<script>
  import { proposalMixin } from '../../mixins/proposalMixin';

  export default {
    props: [
      'shadeGroupId',
      'shadeUnit'
    ],

    data() {
      return {
        inFocus: false,
        justSavedShade: false,
      }
    },

    mixins: [proposalMixin],

    mounted() {
      /*
        console.log('ShadeInstallationTableRow.vue - Mounted');
        console.log('shadeGroupId');
        console.log(this.shadeGroupId);
        console.log('shadeLineItem');
        console.log(this.shadeUnit);
        console.log('');
      */
    },

    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      shadeGroup() {
        return this.$store.getters['shadeGroups/find'](this.shadeGroupId);
      },

      shadeLineItem() {
        return this.shadeUnit;
      },

      afterHoursLaborSelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffAfterHoursLaborOptions;
      },

      extraLaborSelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffExtraLaborOptions;
      },

      supplyOnlySelectOptions() {
        return this.$store.state.orderProposal.selectOptions.takeoffSupplyOnlyOptions;
      },

      fabricName() {
        return this.$store.getters['shadeLineItems/fabricSummaryText'](this.shadeLineItem.id);
      },

      dualFabricName() {
        return this.$store.getters['shadeLineItems/dualFabricSummaryText'](this.shadeLineItem.id);
      },

      errors() {
        return this.$parent.errors;
      },
    },

    methods: {
      getInputName(id, field) {
        return 'order_items['+ id +'][' + field + ']';
      },

      getInputId(id, field) {
        var inputName = this.getInputName(id, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      hasFocus() {
        this.inFocus = ( this.justSavedShade === true ) ? false : true;
        this.justSavedShade = false;
      },

      checkForChanges(event) {
        var hasChanges = false;
        this.shadeGroup.shadeLineItems.forEach(function(sli) {
          if( sli.hasChanges === true ) {
            hasChanges = true;
          }
        });

        if( hasChanges === false || this.inFocus === false ) {
          this.inFocus = false;
          return;

        } else {
          this.saveShadeGroup();
        }
      },

      saveShadeGroup() {
        //As a general rule, when a "singular" shade line item is saved, we actually want to go ahead and save the entire group
        if( this.shadeGroup.isNew == true ) {
          this.$store.dispatch('shadeGroups/createShadeGroup', this.shadeGroup.id);
        } else {
          this.$store.dispatch('shadeGroups/updateShadeGroup', this.shadeGroup.id);
        }

        this.inFocus = false;
        this.justSavedShade = true;
      },
    },

    directives: {
      clickOutside: {
        bind: function (el, binding, vnode) {
          el.clickOutsideEvent = function (event) {
            if( $(event.target).closest(el).length <= 0 && vnode.context.inFocus === true ) {
              vnode.context[binding.expression](event);
            }
          };
          document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unbind: function (el) {
          document.body.removeEventListener('click', el.clickOutsideEvent);
        }
      }
    }
  };
</script>

<template>
  <tr @click="hasFocus" v-click-outside="checkForChanges" v-if="shadeLineItem.isNew === false">
    <td><input type="checkbox" class="checkbox-installation-class" :value="shadeLineItem.id" /></td>
    <td>{{ shadeLineItem.sort_order_number_textual }}</td>
    <td>{{ shadeLineItem.quantity }}</td>
    <td>{{ shadeLineItem.product_model_name }}</td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['supply_only'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(shadeLineItem.id, 'supply_only')"
        v-bind:id="getInputId(shadeLineItem.id, 'supply_only')"
        v-model="shadeLineItem.supply_only"
        :disabled="shadeLineItem.isInnerCoupledShade || orderProposal.userCanEdit == false">
        <option v-for="option in supplyOnlySelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['after_hours'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(shadeLineItem.id, 'after_hours')"
        v-bind:id="getInputId(shadeLineItem.id, 'after_hours')"
        v-model="shadeLineItem.after_hours"
        :disabled="shadeLineItem.isInnerCoupledShade || orderProposal.userCanEdit == false">
        <option v-for="option in afterHoursLaborSelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>{{ shadeLineItem.shade_unit_hours }}</td>
    <td>{{ shadeLineItem.shade_unit_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.trim_unit_hours }}</td>
    <td>{{ shadeLineItem.trim_unit_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.side_channel_unit_hours }}</td>
    <td>{{ shadeLineItem.side_channel_unit_labor_cost_formatted }}</td>
    <td>
      <select
        class="form-control"
        v-bind:class="errors['extra_labor_minutes'] ? 'is-invalid' : ''"
        v-bind:name="getInputName(shadeLineItem.id, 'extra_labor_minutes')"
        v-bind:id="getInputId(shadeLineItem.id, 'extra_labor_minutes')"
        v-model="shadeLineItem.extra_labor_minutes"
        :disabled="shadeLineItem.isInnerCoupledShade || orderProposal.userCanEdit == false">
        <option :value="null">--None--</option>
        <option v-for="option in extraLaborSelectOptions" :value="option.value">{{ option.label }}</option>
      </select>
    </td>
    <td>{{ shadeLineItem.extra_labor_cost_formatted }}</td>
    <td>{{ shadeLineItem.install_unit_hours }}</td>
    <td>{{ shadeLineItem.install_unit_cost_formatted }}</td>
    <td>{{ shadeLineItem.install_total_hours }}</td>
    <td>{{ shadeLineItem.install_total_cost_formatted }}</td>
  </tr>
</template>
