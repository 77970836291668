<script>
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
  import { shadeOrderSettingsMixin } from '../../mixins/shadeOrderSettingsMixin';

  export default {
    props: [],

    mixins: [shadeOrderSettingsMixin],

    data() {
      return {}
    },

    mounted() {
      //
    },

    computed: {
      ...mapFields('shadeOrder', ['shadeOrder']),

      ...mapMultiRowFields('orderProposal', ['orderProposals']),
      orderProposal() {
        return this.orderProposals[0];
      },

      isNew() {
        return this.shadeOrder.isNew;
      },

      orderProposalSelectOptions() {
        return this.$store.state.orderProposal.selectOptions;
      },

      shadeOrderName: {
        get() {
          return this.$store.state.shadeOrder.shadeOrder.name;
        },
        set(value) {
          this.$store.commit('shadeOrder/updateField', {fieldName: 'name', value: value});
        }
      },

      shadeOrderDescription: {
        get() {
          return this.$store.state.shadeOrder.shadeOrder.description;
        },
        set(value) {
          this.$store.commit('shadeOrder/updateField', {fieldName: 'description', value: value});
        }
      },

      errors() {
        return this.$store.state.shadeOrder.shadeOrderErrors;
      },

      errorsOrderProposal() {
        return this.$store.state.orderProposal.errors;
      }
    },

    methods: {
      getInputName(type, field) {
        let fieldLead = ( type == 'order_proposal' ) ? 'order_proposal' : 'shade_order';
        return fieldLead + `[${field}]`;
      },

      getInputId(type, field) {
        var inputName = this.getInputName(type, field);
        return inputName.replace(/\[/g, "_").replace(/\]/g, "");
      },

      getInputTooltipId(type, field) {
        var inputId = this.getInputId(type, field);
        return inputId;
      },

      triggerSubmit(event) {
        event.preventDefault();
        this.$store.dispatch('shadeOrder/saveShadeOrderSettings');

        // location.reload();
      }
    }
  }

</script>
