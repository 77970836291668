<script>
  import { mapState, mapGetters } from 'vuex';
  import DealerCalcTableGroup from './DealerCalcTableGroup';
  import NonMotorizedParts from './NonMotorizedParts';
  import MotorizedParts from './MotorizedParts';

  export default {
    props: [],

    components: {
      DealerCalcTableGroup,
      NonMotorizedParts,
      MotorizedParts,
    },

    data() {
      return {
        showNonMotorizedParts: false,
        showMotorizedParts: false,
      }
    },

    mounted() {},

    computed: {
      orderProposal() {
        return this.$store.getters['orderProposal/currentOrderProposal'];
      },

      ...mapState('shadeGroups', ['byId']),
      ...mapGetters("shadeGroups", { shadeGroups: "list" }),

      toggleNonMotorizedPartsClass() {
        return ( this.showNonMotorizedParts ) ? 'fa fa-minus' : 'fa fa-plus';
      },

      toggleMotorizedPartsClass() {
        return ( this.showMotorizedParts ) ? 'fa fa-minus' : 'fa fa-plus';
      },

      errors() {
        return [];
      },
    },

    methods: {
      toggleNonMotorizedParts(event) {
        event.preventDefault();
        return this.showNonMotorizedParts = !this.showNonMotorizedParts;
      },

      toggleMotorizedParts(event) {
        event.preventDefault();
        return this.showMotorizedParts = !this.showMotorizedParts;
      }
    }
  };
</script>

<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Item</th>
          <th scope="col">Total Material ($)</th>
          <th scope="col">Total MFG Labor ($) </th>
          <th scope="col">Pkg &amp; Handling (Hours)</th>
          <th scope="col">Pkg &amp; Handling ($)</th>
          <th scope="col" class="text-nowrap">Supply Only</th>
          <th scope="col">Total Install Labor ($)</th>
          <th scope="col">Total Cost ($)</th>
          <th scope="col">Unit Cost ($)</th>
          <th scope="col">Labor Expense</th>
          <th scope="col">Material Expense</th>
          <th scope="col">Gross Profit</th>
          <th scope="col">Subtotal</th>
          <th scope="col">Commission Fee</th>
          <th scope="col">Total</th>
          <th scope="col">Cost Per Shade</th>
          <th scope="col">% of Total</th>
        </tr>
      </thead>

      <template v-if="shadeGroups.length <= 0 && orderProposal.addtl_items_total <= 0 && orderProposal.motor_items_total <= 0">
        <tbody>
          <tr>
            <td colspan="17" class="colored-bg text-center">No Order Items</td>
          </tr>
        </tbody>
      </template>

      <template v-else v-for="shadeGroup in shadeGroups">
        <dealer-calc-table-group
          :shade-group-id="shadeGroup.id">
        </dealer-calc-table-group>
      </template>

      <template v-if="shadeGroups.length > 0">
        <tbody>
          <tr class="colored-bg">
            <td><strong>Shade Totals</strong></td>
            <td>{{ orderProposal.total_material_cost_formatted }}</td>
            <td>{{ orderProposal.total_mfg_labor_cost_formatted }}</td>
            <td>{{ orderProposal.order_item_pkg_labor_hours_formatted }}</td>
            <td>{{ orderProposal.order_item_pkg_labor_cost_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.order_item_install_labor_cost_formatted }}</td>
            <td>{{ orderProposal.order_item_total_cost_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.order_item_labor_expense_formatted }}</td>
            <td>{{ orderProposal.order_item_material_expense_formatted }}</td>
            <td>{{ orderProposal.order_item_gross_profit_formatted }}</td>
            <td>{{ orderProposal.order_item_subtotal_formatted }}</td>
            <td>{{ orderProposal.order_item_commission_total_formatted }}</td>
            <td>{{ orderProposal.order_item_total_price_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.order_item_pct_of_total_formatted }}</td>
          </tr>
        </tbody>
      </template>

      <template v-if="orderProposal.addtl_items_total > 0">
        <non-motorized-parts></non-motorized-parts>
        <tbody>
          <tr class="colored-bg">
            <td class="text-nowrap">
              <a href="#" class="pull-left mt-1" v-bind:class="toggleNonMotorizedPartsClass" @click="toggleNonMotorizedParts($event)"></a>
              <strong>Additional&nbsp;Parts</strong>
            </td>
            <td>{{ orderProposal.additional_parts_total_material_formatted }}</td>
            <td>{{ orderProposal.additional_parts_mfg_labor_formatted }}</td>
            <td>{{ orderProposal.additional_parts_pkg_labor_hours }}</td>
            <td>{{ orderProposal.additional_parts_pkg_labor_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.addtl_item_install_labor_cost_formatted }}</td>
            <td>{{ orderProposal.additional_parts_total_cost_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.additional_parts_labor_expense_formatted }}</td>
            <td>{{ orderProposal.additional_parts_material_expense_formatted }}</td>
            <td>{{ orderProposal.additional_parts_gross_profit_formatted }}</td>
            <td>{{ orderProposal.additional_parts_subtotal_formatted }}</td>
            <td>{{ orderProposal.additional_parts_commission_total_formatted }}</td>
            <td>{{ orderProposal.additional_parts_total_price_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.additional_parts_pct_of_total_formatted }}</td>
          </tr>
        </tbody>
      </template>

      <template v-if="orderProposal.motor_items_total > 0">
        <motorized-parts></motorized-parts>
        <tbody>
          <tr class="colored-bg">
            <td class="text-nowrap">
              <a href="#" class="pull-left mt-1" v-bind:class="toggleMotorizedPartsClass" @click="toggleMotorizedParts($event)"></a>
              <strong>Motorized&nbsp;Parts</strong>
            </td>
            <td>{{ orderProposal.motorized_parts_total_material_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_mfg_labor_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_pkg_labor_hours }}</td>
            <td>{{ orderProposal.motorized_parts_pkg_labor_formatted }}</td>
            <td></td>
            <td>{{ orderProposal.motor_item_install_labor_cost_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_total_cost_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.motorized_parts_labor_expense_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_material_expense_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_gross_profit_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_subtotal_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_commission_total_formatted }}</td>
            <td>{{ orderProposal.motorized_parts_total_price_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.motorized_parts_pct_of_total_formatted }}</td>
          </tr>
        </tbody>
      </template>

      <template v-if="shadeGroups.length > 0 || orderProposal.addtl_items_total > 0 || orderProposal.motor_items_total > 0">
        <tbody>
          <tr class="colored-bg">
            <td><strong>Total</strong></td>
            <td>{{ orderProposal.dealer_calc_material_cost_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_mfg_labor_cost_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_pkg_labor_hours }}</td>
            <td>{{ orderProposal.dealer_calc_pkg_labor_cost_formatted }}</td>
            <td></td>
            <td>{{ orderProposal.install_labor_cost_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_total_cost_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.dealer_calc_labor_expense_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_material_expense_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_gross_profit_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_subtotal_formatted }}</td>
            <td>{{ orderProposal.dealer_commission_cost_formatted }}</td>
            <td>{{ orderProposal.dealer_calc_total_price_formatted }}</td>
            <td>&nbsp;</td>
            <td>{{ orderProposal.dealer_calc_pct_of_total_formatted }}</td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>
